import * as React from "react";
import Seo from "../components/seo";

const IndexPage = () => {
  React.useEffect(() => {
    window.location.pathname = "/en";
  }, []);

  return <Seo />;
};

export default IndexPage;
